import { Flex, Text, Heading, Link, Button } from '@odex/uikit'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import Image from 'next/image'
import CommunityVote from '../../../../public/images/home/Object2 2.png'
import ColoredWordLastHeading from './ColoredWordLastHeading'

const Home4 = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const primaryButton = {
    to: '/swap',
    text: 'Buy ODEX',
    external: false,
  }

  return (
    <>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems="center"
        justifyContent="center"
        mt={[account ? '50px' : '50px', null, 0]}
        mb="150px"
        id="homepage-home4"
      >
        <Flex flex="1" flexDirection="column" maxWidth={'775px'}>
          <ColoredWordLastHeading
            color="#ffffff"
            text={t('Discover smooth trading on ODEX, Onchain AI’s DEX.')}
            textAlign={'center'}
          />
          <Text color="#FFFFFF" fontSize="18px" mb="24px" mt="20px" textAlign={'center'}>
            {t('Enjoy fast, secure, and low-fee swaps on the Onchain AI mainnet.')}
          </Text>
          <Flex mt="50px" justifyContent={'center'}>
            <Button>
              <Link external href={primaryButton.to}>
                <Text color="#ffffff" bold fontSize="16px">
                  {primaryButton.text}
                </Text>
              </Link>
            </Button>
          </Flex>
        </Flex>
        {/* <Flex
          height={['auto', null, null, '100%']}
          width={['192px', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
          position="relative"
          justifyContent="center"
          alignSelf="center"
        >
          <Image src={CommunityVote} priority placeholder="blur" alt={t('B swap')} />
        </Flex> */}
      </Flex>
    </>
  )
}

export default Home4
