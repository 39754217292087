import styled, { css, DefaultTheme } from 'styled-components'
import { Box } from '@odex/uikit'
import { ClipFill } from '../types'

interface BccProps {
  clipFill?: ClipFill
}

interface ContainerProps extends BccProps {
  clipPath: string
}

const sharedStyles = (theme: DefaultTheme, clipPath: string, clipFill?: ClipFill) => css`
  width: 100%;
  height: 20px;
  clip-path: url(${clipPath});

  background: ${() => {
    if (theme.isDark) {
      return clipFill?.dark || clipFill?.light || theme.colors.background
    }
    return clipFill?.light || theme.colors.background
  }};

  & svg {
    display: block;
  }
`

const ConcaveContainer = styled(Box)<ContainerProps>`
  ${({ theme, clipPath, clipFill }) => sharedStyles(theme, clipPath, clipFill)}
  transform: ${({ clipPath }) => (clipPath === '#bottomConcaveBcc' ? 'translate(0, -13px)' : 'translate(0, 1px)')};
`

const ConvexContainer = styled(Box)<ContainerProps>`
  ${({ theme, clipPath, clipFill }) => sharedStyles(theme, clipPath, clipFill)}
  transform: ${({ clipPath }) => (clipPath === '#bottomConvexBcc' ? 'translate(0, -13px)' : 'translate(0, -1px)')};
`

export const ConvexTop: React.FC<BccProps> = ({ clipFill }) => (
  <ConvexContainer clipFill={clipFill} clipPath="#topConvexBcc">
    <svg width="0" height="0">
      <defs>
        <clipPath id="topConvexBcc" clipPathUnits="objectBoundingBox">
          <path d="M 0,1 L 0,0 L 1,0 L 1,1 C 0.75 0, .25 0, 0 1 Z" />
        </clipPath>
      </defs>
    </svg>
  </ConvexContainer>
)

export const ConvexBottom: React.FC<BccProps> = ({ clipFill }) => (
  <ConvexContainer clipFill={clipFill} clipPath="#bottomConvexBcc">
    <svg width="0" height="0">
      <defs>
        <clipPath id="bottomConvexBcc" clipPathUnits="objectBoundingBox">
          <path d="M 0,0 L 0,1 L 1,1 L 1,0 C .75 1, .25 1, 0 0 Z" />
        </clipPath>
      </defs>
    </svg>
  </ConvexContainer>
)

export const ConcaveTop: React.FC<BccProps> = ({ clipFill }) => (
  <ConcaveContainer clipFill={clipFill} clipPath="#topConcaveBcc">
    <svg width="0" height="0">
      <defs>
        <clipPath id="topConcaveBcc" clipPathUnits="objectBoundingBox">
          <path d="M 0,0 L 0,1 L 1,1 L 1,0 C .75 1, .25 1, 0 0 Z" />
        </clipPath>
      </defs>
    </svg>
  </ConcaveContainer>
)

export const ConcaveBottom: React.FC<BccProps> = ({ clipFill }) => (
  <ConcaveContainer clipFill={clipFill} clipPath="#bottomConcaveBcc">
    <svg width="0" height="0">
      <defs>
        <clipPath id="bottomConcaveBcc" clipPathUnits="objectBoundingBox">
          <path d="M 0,1 L 0,0 L 1,0 L 1,1 C .75 0.1, .25 0.1, 0 1 Z" />
        </clipPath>
      </defs>
    </svg>
  </ConcaveContainer>
)
